<template>
  <div
    ref="containerRef"
    :class="[$style.container, { ProductDetailTabs__shopContainer: isShop === true }]"
  >
    <div id="tabs" :class="$style.anchor" />
    <div v-if="!isMobile" :class="$style.tabs">
      <a
        v-for="(tab, index) in config.length"
        :key="`${index}-aKey`"
        :class="[
          $style.tab,
          { ProductDetailTabs__active: activeTab === tab },
          { ProductDetailTabs__disabled: config[index].disabled },
          { ProductDetailTabs__hidden: config[index].disabled && config.length == 1 }
        ]"
        @click="(e) => updateTabs(tab, e)"
      >
        {{ config[index].title }}
      </a>
      <div
        v-for="(tab, index) in config.length"
        :key="`${index}-bKey`"
        :class="[$style.content, { ProductDetailTabs__activeContent: activeTab === tab }]"
      >
        <div v-if="contentLoaded === false && !config[index].isConfigurator" class="preloadFilter">
          <Loading />
        </div>
        <div
          v-if="
            activeTab === tab &&
            !config[index].isDownload &&
            !config[index].isTable &&
            !config[index].isConfigurator &&
            contentLoaded
          "
          v-html="content"
        />
        <ProductDownloadsSection
          v-if="activeTab === tab && config[index].isDownload && contentLoaded"
          :initial-select-option="config[index].initialSelectOption"
          :select-all-option="config[index].selectAllOption"
          :downloads="content.downloads"
          :config="sectionConfig"
        />
        <Configurator
          v-if="activeTab === tab && config[index].isConfigurator"
          :config="config[index].configurator"
          :shop-config="shopConfig"
        />
        <div v-if="config[index].isTable" class="ProductTable__container">
          <div v-if="activeTab === tab && contentLoaded" v-html="content" />
        </div>
        <section
          v-if="activeTab === 1 && config[index].sliders && config[index].sliders.length > 0"
          :class="$style.sliderSection"
        >
          <ProductSlider
            v-for="(slider, sliderIndex) in config[index].sliders"
            :key="`${sliderIndex}-sKey`"
            :config="slider"
          />
        </section>
      </div>
    </div>
    <div v-if="isMobile" :class="[$style.tabs, $style.mobile]">
      <div v-for="(tab, index) in config.length" :key="`${index}-cKey`">
        <a
          :class="[
            $style.tab,
            {
              ProductDetailTabs__active: activeTab === tab && mobileHidden !== tab
            },
            {
              ProductDetailTabs__configurator: config[index].isConfigurator
            },
            { ProductDetailTabs__disabled: config[index].disabled },
            { ProductDetailTabs__hidden: config[index].disabled && config.length == 1 }
          ]"
          @click="(e) => updateTabs(tab, e)"
        >
          {{ config[index].title }}
        </a>
        <div
          :class="[
            $style.content,
            {
              ProductDetailTabs__activeContent: activeTab === tab && !isMobile
            },
            {
              ProductDetailTabs__activeContent:
                activeTab === tab && isMobile && mobileHidden !== tab
            }
          ]"
        >
          <div
            v-if="contentLoaded === false && !config[index].isConfigurator"
            class="preloadFilter"
          >
            <Loading />
          </div>
          <div
            v-if="
              activeTab === tab &&
              !config[index].isDownload &&
              !config[index].isTable &&
              !config[index].isConfigurator &&
              contentLoaded
            "
            v-html="content"
          />
          <ProductDownloadsSection
            v-if="activeTab === tab && config[index].isDownload && contentLoaded"
            :initial-select-option="config[index].initialSelectOption"
            :select-all-option="config[index].selectAllOption"
            :downloads="content.downloads"
            :config="sectionConfig"
          />
          <Configurator
            v-if="activeTab === tab && config[index].isConfigurator"
            :config="config[index].configurator"
            :shop-config="shopConfig"
          />
          <div v-if="config[index].isTable" class="ProductTable__container">
            <div v-if="activeTab === tab && contentLoaded" v-html="content" />
          </div>
          <section
            v-if="activeTab === 1 && config[0].sliders && config[0].sliders.length > 0"
            :class="$style.sliderSection"
          >
            <ProductSlider
              v-for="(slider, sliderIndex) in config[0].sliders"
              :key="`${sliderIndex}-sKey`"
              :config="slider"
            />
          </section>
        </div>
      </div>
    </div>
    <Modal
      v-if="isShop"
      :class="$style.shopModal"
      :is-modal-open="isModalOpen"
      @close-modal="closeShopModal"
      @open-modal="openModal"
    >
      <div :class="$style.modal">
        <p :class="$style.heading">{{ shopConfig.inputPlaceholder }}: {{ articleNumber }}</p>
        <div class="columns">
          <div class="column is-two-thirds-tablet">
            <p :class="$style.subHeading">
              {{ shopConfig.articleDetails }}
            </p>
            <a
              v-if="dataSheetUrl && shopConfig.showDatasheetLink"
              :class="$style.docLink"
              @click="getDataSheet"
            >
              <template v-if="!isDataSheetLoading">
                {{ shopConfig.dataSheetText }}
              </template>
              <template v-else>
                <span>{{ shopConfig.dataSheetLoadingText }}</span>
                <i class="fas fa-spinner fa-spin" />
              </template>
            </a>
            <p v-html="data.longtext" />
          </div>
          <div class="column is-one-third-tablet">
            <div :class="$style.addToCart">
              <div class="columns is-mobile">
                <div :class="[$style.label, 'column']">{{ shopConfig.pricePerItem }}:</div>
                <div :class="[$style.label, 'column']">
                  <IndividualPrice
                    :value="Number(price)"
                    :product-type="data.productType"
                    :quantity="1"
                    :btype-list="data.btypeList"
                  />
                </div>
              </div>
              <div :class="['columns', 'is-mobile', $style.countSum]">
                <div :class="[$style.label, 'column']">{{ shopConfig.quantity }}:</div>
                <Select
                  :select-options="selectOptions"
                  :select-value="quantity"
                  colour="white"
                  @on-change="priceSum"
                />
              </div>
              <hr />
              <div :class="$style.sum">
                <IndividualPrice
                  :value="Number(price)"
                  :product-type="data.productType"
                  :quantity="Number(quantity)"
                  :btype-list="data.btypeList"
                />
              </div>
              <div :class="$style.tax">
                {{ shopConfig.tax }} &amp;
                <a :href="shopConfig.shippingUrl">{{ shopConfig.shippingText }}</a>
              </div>
              <div v-if="data.deliveryCategory === 1" :class="$style.deliveryStatusGreen">
                <span :class="$style.deliveryStatus" />
                {{ shopConfig.readyForShipping }}
              </div>
              <div v-if="data.deliveryCategory > 1" :class="$style.deliveryStatusOrange">
                <span :class="$style.deliveryStatus" />
                {{ shopConfig.delivery }}: {{ deliveryDate }}
              </div>
              <div :class="[$style.buttons, { ProductDetailTabs__buttonsHidden: addingToCart }]">
                <ShopCartLink
                  :key="`cartLink-${componentKey}`"
                  :item="{
                    text: shopConfig.addButtonText,
                    quantity: quantity,
                    id: articleNumber,
                    headline: shopConfig.headline,
                    subheadline: shopConfig.subheadline,
                    pictureurl: shopConfig.pictureurl,
                    thumbnailurl: shopConfig.thumbnailurl,
                    producturl: shopConfig.producturl
                  }"
                  :add-item-failed-msg="shopConfig.addArticleFailedMessage"
                  :add-button-loading-text="shopConfig.addButtonLoadingText"
                  @closed="addToCart"
                />
                <div
                  :class="[
                    $style.loadingBasket,
                    { ProductDetailTabs__loadingBasketVisible: addingToCart }
                  ]"
                >
                  <div :class="$style.loadingContainer">
                    <Loading size="20" />
                  </div>
                  {{ shopConfig.addButtonLoadingText }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios'
import { useTippy } from 'vue-tippy'
import CustomEvents from '../../globals/constants/customEvents'
import StorageKeys from '../../globals/constants/storageKeys'
import * as Storage from '../../shared/Storage'
import { createDataSheetUrl, sessionLoginUrl, sessionLogoutUrl } from '../../shared/globals'
import { b64toBlob } from '../../utils/convertData'
import { convertISODateTimeToISODate, convertISODateTimeToLocaleDate } from '../../utils/dateTime'
import { checkIsMobile } from '../../utils/media'
import { sortCollectionInGivenArray } from '../../utils/sort'
import getDeliveryDateFromUrl from '../../xhr/product/getDeliveryDateFromUrl'
import Configurator from '../Configurator/Configurator.vue'
import IndividualPrice from '../IndividualPrice/IndividualPrice.vue'
import Loading from '../Loading/Loading.vue'
import Modal from '../Modal/Modal.vue'
import ProductDownloadsSection from '../ProductDownloadsSection/ProductDownloadsSection.vue'
import ProductSlider from '../ProductSlider/ProductSlider.vue'
import Select from '../Select/Select.vue'
import ShopCartLink from '../ShopCartLink/ShopCartLink.vue'

export default {
  name: 'ProductDetailTabs',
  components: {
    Modal,
    Select,
    ProductSlider,
    ProductDownloadsSection,
    ShopCartLink,
    Loading,
    Configurator,
    IndividualPrice
  },
  props: {
    config: {
      type: Array,
      default: () => []
    },
    shopConfig: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      activeTab: 1,
      activeTabPreselected: false,
      addingToCart: false,
      articleData: null,
      articleNumber: null,
      componentKey: 0,
      content: null,
      contentLoaded: false,
      currentRadioID: null,
      currentTableId: null,
      currentUrl: [],
      data: {},
      dataSheetUrl: null,
      deliveryCategory: '',
      deliveryDate: null,
      detailLinkText: '',
      emTable: null,
      firstTableLoad: true,
      hideTooltip: false,
      isDataSheetLoading: false,
      isMobile: false,
      isModalOpen: false,
      isShop: false,
      mobileHidden: null,
      price: null,
      productTypes: '',
      quantity: '1',
      queriedItemID: null,
      queriedItemShowed: false,
      radios: null,
      RFQText: '',
      RFQUrl: '',
      scrollWarning: '',
      sectionConfig: {},
      shopPageHeader: null,
      shopLinkText: '',
      tableTabIsActive: false,
      techInfo: null,
      currentTab: null,
      tableCache: new Map()
    }
  },
  computed: {
    selectOptions: function () {
      const range = (start, end) => [...Array(end - start + 1)].map((_, i) => start + i).map(String)
      return range(1, this.shopConfig.selectOptionMax)
    },
    totalPrice: function () {
      return this.data.price * this.quantity
    },
    highlightedText: function () {
      const searchParams = new URLSearchParams(window.location.search);

      return searchParams.get('highlightedText')
    }
  },
  mounted() {
    if (window.instance === 'shop') {
      this.isShop = true
    }

    this.dataSheetUrl = window.createDataSheetUrl || createDataSheetUrl

    // * events

    window.addEventListener('resize', this.checkScreenSize)
    window.addEventListener('keyup', this.escCloseModal)
    window.addEventListener('addToLocalStorage', (event) => this.sendDataToRFQForm(event.detail))
    window.addEventListener(CustomEvents.OPEN_TAB_FROM_LINK, (event) =>
      this.updateTabsFromLink(event.detail)
    )
    window.addEventListener(CustomEvents.GET_DATSHEET_FROM_ARTICLE, (event) => {
      event.detail.element.innerHTML =
        '<span>' + this.dataSheetLoadingText + '</span><i class="fas fa-spinner fa-spin"></i>'
      this.articleNumber = event.detail.itemid
      this.getDataSheet()
    })

    // * init

    const init = () => {
      this.checkLocalStorage()
      this.checkScreenSize()
      this.initTabs()
    }

    if (this.highlightedText) {
      const tableFilenames = this.config.filter(item => item.isTable).flatMap(item => item.url)

      axios.all(tableFilenames.map(async (filename) => {
        const tableContent = await axios.get(filename);

        this.tableCache.set(filename, tableContent)

        const tableContentHTML = document.createElement('div');

        tableContentHTML.innerHTML = tableContent.data;
        const selectValues = [...tableContentHTML.querySelectorAll('input[name="orderDataItem"]')].map(item => item.value)


        for (const selectValue of selectValues) {
          if (!this.tableCache.has(selectValue)) {
            const subTableContent = await axios.get(selectValue);
            this.tableCache.set(selectValue, subTableContent)
          }

        }

        return true
      })).finally(init)
    } else {
      init()
    }
  },
  unmounted() {
    // * events

    window.removeEventListener('resize', this.checkScreenSize)
    window.removeEventListener('keyup', this.escCloseModal)
    window.removeEventListener('addToLocalStorage', (event) => this.sendDataToRFQForm(event.detail))
    window.removeEventListener(CustomEvents.OPEN_TAB_FROM_LINK, (event) =>
      this.updateTabsFromLink(event.detail)
    )
    window.removeEventListener(CustomEvents.GET_DATSHEET_FROM_ARTICLE, (event) => {
      event.detail.element.innerHTML =
        '<span>' + this.dataSheetLoadingText + '</span><i class="fas fa-spinner fa-spin"></i>'

      this.articleNumber = event.detail.itemid

      this.getDataSheet()
    })
  },
  methods: {
    forceRerender() {
      this.componentKey += 1
    },
    checkScreenSize() {
      const currentState = this.isMobile

      this.isMobile = checkIsMobile()

      if (currentState !== this.isMobile && this.tableTabIsActive) {
        this.initProductTable()
      }
    },
    initContent() {
      this.contentLoaded = true
    },
    initTableContent() {
      this.tableTabIsActive = true

      const index = this.activeTab - 1

      this.scrollWarning = this.config[index].scrollWarning
      this.detailLinkText = this.config[index].detailLinkText
      this.RFQText = this.config[index].RFQText
      this.RFQUrl = this.config[index].RFQUrl
      this.productTypes = this.config[index].productTypes
      this.shopLinkText = this.config[index].shopLinkText
      this.shopUrl = this.config[index].shopUrl

      this.initProductTable()

      this.dataSheetText = this.config[index].dataSheetText
      this.showDatasheetLink = this.config[index].showDatasheetLink
      this.dataSheetLoadingText = this.config[index].dataSheetLoadingText
    },
    initTabs() {
      const queries = window.location.search.substring(1).split('&')
      let queryStringTabId = null

      if (queries) {
        for (const query of queries) {
          if (query.includes('tab')) {
            queryStringTabId = query.split('=')[1]
          }
          if (query.includes('itemId')) {
            this.queriedItemID = query.split('=')[1]
          }
        }
      }
      if (queryStringTabId) {
        for (const [index, tab] of this.config.entries()) {
          if (tab.id && queryStringTabId === tab.id && !tab.disabled) {
            this.activeTab = index + 1
            this.activeTabPreselected = true

            if (tab.url) {
              this.currentUrl = tab.url
              this.sectionConfig = this.config[index]

              this.getContentFromUrl(tab.url)
            } else {
              this.getContentFromId(tab.id)
            }
          }
        }
      } else {
        for (const [index, tab] of this.config.entries()) {
          if (tab.active && !tab.disabled) {
            this.activeTab = index + 1
            this.activeTabPreselected = true

            if (tab.url) {
              this.currentUrl = tab.url

              this.getContentFromUrl(tab.url)
            } else if (tab.id) {
              this.getContentFromId(tab.id)
            }
          }
        }
      }
      if (!this.activeTabPreselected) {
        const firstNotDisabledTabIndex = this.config.findIndex((tab) => !tab.disabled)

        this.activeTab = firstNotDisabledTabIndex + 1
        this.contentLoaded = false

        if (this.config[firstNotDisabledTabIndex].url) {
          this.currentUrl = this.config[firstNotDisabledTabIndex].url

          this.getContentFromUrl(this.config[firstNotDisabledTabIndex].url)
        } else if (this.config[firstNotDisabledTabIndex].id) {
          this.getContentFromId(this.config[firstNotDisabledTabIndex].id)
        } else {
          this.contentLoaded = true
        }
      }
    },
    updateTabs(tab, $event) {
      const elm = $event.currentTarget

      if (!elm.classList.contains('ProductDetailTabs__disabled')) {
        this.updateTabContent(tab)
      }
    },
    updateTabsFromLink(tabID) {
      for (let i = 0; i < this.config.length; i++) {
        if (this.config[i].tabID === tabID && !this.config[i].disabled) {
          this.updateTabContent(i + 1)
        }
      }
    },
    updateTabContent(tab) {
      this.contentLoaded = false

      if (this.isMobile && this.activeTab === tab && this.activeTab !== this.mobileHidden) {
        this.mobileHidden = tab
      } else if (this.isMobile && this.activeTab === tab && this.activeTab === this.mobileHidden) {
        this.mobileHidden = null
      } else if (this.isMobile && this.activeTab !== tab) {
        this.mobileHidden = null
        this.activeTab = tab
      } else {
        this.activeTab = tab
      }

      if (this.config[tab - 1].url) {
        this.currentUrl = this.config[tab - 1].url

        this.getContentFromUrl(this.currentUrl)

        if (!this.config[tab - 1].isTable) {
          this.tableTabIsActive = false

          if (this.config[tab - 1].isDownload) {
            this.sectionConfig = this.config[tab - 1]
          }
        }
      } else if (this.config[tab - 1].id) {
        if (!this.config[tab - 1].isTable) {
          this.tableTabIsActive = false
        }

        this.getContentFromId(this.config[tab - 1].id)
      } else {
        this.tableTabIsActive = false
        this.content = null
      }

      this.scrollTabIntoView()
    },
    scrollTabIntoView() {
      if (!this.isMobile) {
        return
      }

      const { $refs } = this

      window.setTimeout(() => {
        const activeTab = $refs['containerRef'].querySelector('.ProductDetailTabs__active')

        activeTab?.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })
      }, 500)
    },
    getContentFromId(id) {
      this.firstTableLoad = true

      const isTable = this.config[this.activeTab - 1].isTable || false

      this.hideTooltip = this.config[this.activeTab - 1].hideTooltip || false

      const html = document.getElementById(id)

      if (html) {
        this.content = html.innerHTML
        this.contentLoaded = true

        if (isTable) {
          this.currentTableId = id
          this.initTableContent()
        }
      } else {
        this.content = null
        this.contentLoaded = false
      }
    },
    updateContentFromId(id) {
      const html = document.getElementById(id)

      if (html) {
        this.content = html.innerHTML
        this.contentLoaded = true
      } else {
        this.content = null
        this.contentLoaded = false
      }
    },
    mergeData(responses) {
      let mergedData = {}

      for (const response of responses.map((x) => x.data)) {
        if (response.downloads) {
          // ? Check if element contains projectdocuments, approvals or declarations

          if (Array.isArray(response.downloads)) {
            mergedData.downloads = response.downloads
          } else if (
            response.downloads.approvals &&
            response.downloads.approvals.files.length > 0
          ) {
            const approvals = response.downloads.approvals

            approvals.viewType = 'approvals'
            approvals.label = this.sectionConfig.labels?.approvals ?? 'approvals'

            mergedData.downloads.push(approvals)
          } else if (
            response.downloads.projectdocuments &&
            response.downloads.projectdocuments.files.length > 0
          ) {
            const projectdocuments = response.downloads.projectdocuments

            projectdocuments.viewType = 'projectdocuments'
            projectdocuments.label =
              this.sectionConfig.labels?.projectdocuments ?? 'projectdocuments'

            mergedData.downloads.push(projectdocuments)
          } else if (
            response.downloads.declarations &&
            response.downloads.declarations.files.length > 0
          ) {
            const declarations = response.downloads.declarations
            declarations.viewType = 'declarations'
            declarations.label = this.sectionConfig.labels?.declarations ?? 'declarations'

            mergedData.downloads.push(declarations)
          }
        }
      }
      return mergedData
    },
    getContentFromUrl(urls) {
      const isDownload = this.config[this.activeTab - 1].isDownload || false
      const isTable = this.config[this.activeTab - 1].isTable || false

      axios
        .all([...urls].map((x) => this.highlightedText && isTable ? this.tableCache.get(x) : axios.get(x)))
        .then((response) => {
          if (isDownload) {
            this.content = this.mergeData(response)
            this.content.downloads = sortCollectionInGivenArray(
              [...this.content.downloads],
              'asc',
              'sortValue'
            )
          } else {
            this.content = response[0].data

            if (isTable) {
              this.initTableContent()
            }
          }
          this.initContent()
        })
        .catch((error) => {
          console.error(error)

          this.content = null
          this.contentLoaded = false
        })
    },
    updateContentFromUrl(urls) {
      const isDownload = this.config[this.activeTab - 1].isDownload || false
      const isTable = this.config[this.activeTab - 1].isTable || false

      axios
        .all([...urls].map((x) => this.highlightedText && isTable ? this.tableCache.get(x) : axios.get(x)))
        .then((response) => {
          if (isDownload) {
            this.content = this.mergeData(response)

            sortCollectionInGivenArray([...this.content.downloads], 'asc', 'sortValue')
          } else {
            this.content = response[0].data
          }
          this.contentLoaded = true
        })
        .catch((error) => {
          console.error(error)

          this.content = null
          this.contentLoaded = false
        })
    },
    getNewTable(val, id) {
      this.currentRadioID = id

      if (this.currentTableId) {
        if (this.currentTableId !== val) {
          this.currentTableId = id
          this.contentLoaded = false
          this.firstTableLoad = false

          this.updateContentFromId(val)
          this.initProductTable()
        } else if (this.firstTableLoad && this.techInfo) {
          this.emTable.style.display = 'table'
          this.techInfo.style.display = 'block'

          this.emTableCreate()

          this.firstTableLoad = false
        }
      } else if (this.currentUrl[0] !== val) {
        const urls = [val]

        this.currentUrl = urls
        this.contentLoaded = false
        this.firstTableLoad = false

        this.updateContentFromUrl(urls)
        this.initProductTable()
      } else if (this.firstTableLoad && this.techInfo) {
        this.emTable.style.display = 'table'
        this.techInfo.style.display = 'block'

        this.emTableCreate()

        this.firstTableLoad = false
      }
    },
    initProductTable() {
      setTimeout(() => {
        if (this.contentLoaded === false) {
          this.initProductTable()
        } else {
          const tableContainer = document.querySelector('.ProductTable__container')

          this.emTable = tableContainer.querySelector('.em-table-slide')
          this.radios = tableContainer.querySelector('.ProductTable__radios')
          this.techInfo = tableContainer.querySelector('.ProductTable__techInfo')

          if (this.radios) {
            const selects = [...this.radios.querySelectorAll('.orderDataItem')]

            selects.forEach((radio) => {
              radio.addEventListener('change', (event) => {
                const { id, value } = event.currentTarget

                this.emTable.style.display = 'none'
                this.getNewTable(value, id)
              })
            })

            if (this.firstTableLoad) {
              if (this.highlightedText && this.tableCache.size > 1) {
                const tableIndex = [...this.tableCache.values()].findIndex(table => table.data.includes(this.highlightedText))

                window.setTimeout(selects.at(tableIndex)?.click(), 100)
              }

              this.emTable.style.display = 'none'
            } else if (this.techInfo) {
              this.techInfo.style.display = 'block'
              this.emTableCreate()
              this.radios.querySelector(`#${CSS.escape(this.currentRadioID)}`).checked = true
            }
          } else if (this.techInfo) {
            this.techInfo.style.display = 'block'
            this.emTableCreate()
          }
        }
      }, 200)
    },
    emTableCreate() {
      if (this.emTable) {
        const legends = this.techInfo.querySelectorAll('.em-table-legend-shop')

        if (window.instance !== 'shop') {
          legends.forEach((legend) => (legend.style.display = 'none'))

          if (!window.shopAvailable || (window.shopAvailable && !window.isShopPageAvailable)) {
            legends.forEach((legend) =>
              legend
                .querySelectorAll(':scope > .not-online')
                .forEach((notOnline) => (notOnline.style.display = 'none'))
            )
          } else {
            legends.forEach((legend) =>
              legend
                .querySelectorAll(':scope > li')
                .forEach((li) => (li.classList.add = 'shopview'))
            )
            legends.forEach((legend) =>
              legend
                .querySelectorAll(':scope > li span')
                .forEach((el) => (el.classList.add = 'shopview'))
            )
          }
        } else {
          legends.forEach((legend) => (legend.style.display = 'none'))
        }

        const listLength = this.emTable
          .querySelector('tbody > tr:not(.seperator)')
          .querySelectorAll(':scope > td:not(.fixed-col)').length

        //wrap table
        const vueElement = this

        const maxListLength = window.instance === 'shop' ? 8 : 6

        if (this.emTable.dataset.init !== '1') {
          const uniqueID = Math.random().toString(36).substring(2, 9)

          const nav = `<div class="button-navigation" data-ID="${uniqueID}">
              <span class="Button__button Button__orange prev disabled">
                <a class="arrow">Previous</a>
              </span>
              <span class="Button__button Button__orange next">
                <a class="arrow">Next</a>
              </span>
            </div>`

          // add id to filter

          this.emTable.closest('#tabContent').querySelector('.productFilter').dataset.id = uniqueID

          // append navigation and minimap

          if (listLength > maxListLength) {
            const productFilter = this.techInfo.querySelector(
              '.productFilter[data-id="' + uniqueID + '"]'
            )

            productFilter.innerHTML = productFilter.innerHTML + nav
          } else {
            vueElement.calcColWidth()

            if (window.instance !== 'shop') {
              this.techInfo
                .querySelectorAll('.productFilter.orderData')
                .forEach((el) => (el.style.display = 'none'))
            }
          }

          // append table wrap

          this.emTable.id = uniqueID
          this.emTable.dataset.init = 1

          const outerWrapper = document.createElement('div')
          outerWrapper.classList.add('em-table-outer-wrap')

          this.emTable.parentElement.insertBefore(outerWrapper, this.emTable)

          const innerWrapper = document.createElement('div')
          innerWrapper.classList.add('em-table-inner-wrap')

          outerWrapper.appendChild(innerWrapper)
          innerWrapper.appendChild(this.emTable)

          const warning = document.createElement('div')
          warning.classList.add('mobile-scroll-warning')
          warning.innerHTML = vueElement.scrollWarning

          outerWrapper.parentElement.insertBefore(warning, outerWrapper)
        }

        this.calcColHeight()

        if (window.instance !== 'shop' && !window.shopAvailable) {
          this.techInfo
            .querySelectorAll('.em-table-legend .not-online')
            .forEach((notOnline) => (notOnline.style.display = 'none'))
        } else if (window.instance === 'shop' || window.shopAvailable) {
          // ? remove hyperlink and change class in cells with deliveryCategory above 2 when in shop

          this.emTable.querySelectorAll('tbody input[type="hidden"]').forEach((input) => {
            if (
              input.id.indexOf('DeliveryCategory') > -1 &&
              window.disallowedDCs.indexOf(parseInt(input.value)) > -1
            ) {
              input.parentElement.classList.remove('after-production', 'in-stock')
              input.parentElement.classList.add('not-online')

              // ? only remove tooltip listner if we inside the shop. Website should show longtext information as well for all articles not online available

              if (window.instance === 'shop') {
                input.parentElement.innerHTML = input.id.replace('DeliveryCategory_', '')
              }
            }
          })
        }
        if (window.instance !== 'shop' && !this.hideTooltip) {
          this.createTooltips()
        }

        this.setupEvents()

        window.initIndividualPrice?.()

        setTimeout(() => {
          this.emTable.style.opacity = 1
          this.emTable.style.display = 'table'
          this.techInfo.querySelector('.productFilter').style.opacity = 1

          this.showQueriedItem()
        }, 500)

        // * highlight


        if (this.highlightedText) {
          const idToHighlight = this.emTable.querySelector("input[name='" + this.highlightedText + "']")

          idToHighlight?.parentElement.classList.add('highlightText')
        }
      }
    },
    showQueriedItem() {
      if (this.isShop && this.queriedItemID && !this.queriedItemShowed) {
        this.queriedItemShowed = true

        const tooltip = this.emTable.querySelector("input[name='" + this.queriedItemID + "']")

        tooltip?.parentNode.querySelector('.tooltip-listener').click()
      }
    },
    checkLocalStorage() {
      this.articleData = Storage.getLocalStorage(StorageKeys.ARTICLE_DATA, true)

      if (!this.articleData) {
        this.articleData = []

        Storage.setLocalStorage(StorageKeys.ARTICLE_DATA, this.articleData, true)
      }
    },
    sendDataToRFQForm(eventDetail) {
      const regexBreak = /\n/g
      let article = ''
      let detailText = ''

      if (eventDetail.id) {
        article = eventDetail.id.toString()
        detailText = eventDetail.detailText
      } else {
        article = eventDetail.toString()
        detailText = document.getElementById(`${article}`).value
      }

      detailText =
        detailText && regexBreak.test(detailText)
          ? detailText.replace(regexBreak, '<br />')
          : detailText

      if (this.articleData.length === 0) {
        this.articleData = [
          {
            productTypes: this.productTypes,
            articles: [{ number: article, total: '1', detailText: detailText }]
          }
        ]
      } else {
        for (const item of this.articleData) {
          if (item.productTypes.includes(this.productTypes)) {
            for (const numberObject of item.articles) {
              if (numberObject.number === article) {
                if (this.RFQUrl.length > 0) {
                  window.location = this.RFQUrl
                }
                return
              }
            }

            item.articles.push({
              number: article,
              total: '1',
              detailText: detailText
            })

            Storage.setLocalStorage(StorageKeys.ARTICLE_DATA, this.articleData, true)
            if (this.RFQUrl.length > 0) {
              window.location = this.RFQUrl
            }

            return
          }
        }

        this.articleData.push({
          productTypes: this.productTypes,
          articles: [{ number: article, total: '1', detailText: detailText }]
        })
      }

      Storage.setLocalStorage(StorageKeys.ARTICLE_DATA, this.articleData, true)
      if (this.RFQUrl.length > 0) {
        window.location = this.RFQUrl
      }
    },
    createTooltips() {
      const detailLinkText = this.detailLinkText
      const RFQText = this.RFQText
      const shopLinkText = this.shopLinkText
      const shopUrl = this.shopUrl
      const dataSheetText = this.dataSheetText
      const showDatasheetLink = this.showDatasheetLink

      const tooltips = [...this.emTable.querySelectorAll('.tooltip-listener')].map(
        (tooltip) => tooltip.parentElement
      )

      tooltips.forEach((tooltip) => {
        const tipListener = tooltip.querySelector('.tooltip-listener')
        const modalContent = tipListener.nextElementSibling.value
        const deliveryCategory = tooltip.querySelector(
          "input[type='hidden'][id*='DeliveryCategory_']"
        ).value

        const modalDataSheetLink = showDatasheetLink
          ? `
            <li><a class="ProductDetailTabs__docLink" onclick="
                event.preventDefault();
                event.stopPropagation();
                window.dispatchEvent(new CustomEvent('getDatasheetFromArticle', {detail: {
                  itemid: ${tipListener.innerHTML},
                  element: this
                }}));
              "><i class="fas fa-file-pdf"></i>${dataSheetText}
            </a></li>`
          : ''

        let tip = ''

        if (
          window.shopAvailable &&
          deliveryCategory > 0 &&
          deliveryCategory <= window.deliveryCategoryThreshold
        ) {
          const toolTipShowUrl = `${window.isShopPageAvailable ? shopUrl : `${window.shopStartpageUrl}?itemId=`
            }${tipListener.innerHTML}`

          tip = `
            <ul class="DownloadBox__downloadLinks">
              <li>
                <a
                  class="showProductInfo"
                  data-itemid="${tipListener.innerHTML}"
                  onclick="
                    event.preventDefault();
                    event.stopPropagation();
                    document.querySelector('html').classList.add('is-clipped');
                    event.currentTarget.closest('td').firstElementChild.querySelector('.modal').classList.add('is-active');
                    document.body.classList.add('modal-active');"
                >
                  <i class="fas fa-file-alt"></i>${detailLinkText}
                </a>
              </li>
			        ${modalDataSheetLink}
              <li>
                <a
                  class="requestForQuotation"
                  data-itemid="${tipListener.innerHTML}"
                  onclick="
                    event.preventDefault();
                    event.stopPropagation();
                    window.dispatchEvent(new CustomEvent('addToLocalStorage', {detail: ${tipListener.innerHTML}}));"
                >
                  <i class="fas fa-file-medical"></i>${RFQText}
                </a>
              </li>
              <li>
                <a
                  class="toShopPage"
                  data-itemid="${tipListener.innerHTML}"
                  target="_blank"
                  href="${toolTipShowUrl}"
                  onclick="window.wtTrack({ cat: 'TOONLINESHOP' })"
                >
                  <i class="fas fa-shopping-cart"></i>${shopLinkText}
                </a>
              </li>
            </ul>
          `
        } else {
          tip = `
            <ul class="DownloadBox__downloadLinks">
              <li>
                <a
                  class="showProductInfo"
                  data-itemid="${tipListener.innerHTML}"
                  onclick="
                    event.preventDefault();
                    event.stopPropagation();
                    document.querySelector('html').classList.add('is-clipped');
                    event.currentTarget.closest('td').firstElementChild.querySelector('.modal').classList.add('is-active');
                    document.body.classList.add('modal-active');"
                >
                  <i class="fas fa-file-alt"></i>${detailLinkText}
                </a>
              </li>
			        ${modalDataSheetLink}
              <li>
                <a
                  class="requestForQuotation"
                  data-itemid="${tipListener.innerHTML}"
                  onclick="
                    event.preventDefault();
                    event.stopPropagation();
                    window.dispatchEvent(new CustomEvent('addToLocalStorage', {detail: ${tipListener.innerHTML}}));"
                >
                  <i class="fas fa-file-medical"></i>${RFQText}
                </a>
              </li>
            </ul>
          `
        }

        const modal = `
          <div class="Modal__container modal" id="modal${tipListener.innerHTML}">
            <div
              class="modal-background"
              onclick="
                event.preventDefault();
                event.stopPropagation();
                document.querySelector('html').classList.remove('is-clipped');
                document.querySelector('.em-table-inner-wrap').style.overflowX = null;
                document.querySelector('.modal.is-active').classList.remove('is-active');
                document.body.classList.remove('modal-active');
              "
            ></div>
            <div class="modal-content">
              <div class="Modal__content">
                <div class="Modal__title">${detailLinkText}</div>
                ${modalContent}
              </div>
            </div>
            <button
              class="modal-close is-large"
              aria-label="close"
              onclick="
                event.preventDefault();
                event.stopPropagation();
                document.querySelector('html').classList.remove('is-clipped');
                document.querySelector('.em-table-inner-wrap').style.overflowX = null;
                document.querySelector('.modal.is-active').classList.remove('is-active');
                document.body.classList.remove('modal-active');"
            ></button>
          </div>
        `

        tipListener.innerHTML = `<span class="tooltip-listener">${tipListener.innerHTML}</span>` + modal

        useTippy(tipListener, {
          allowHTML: true,
          arrow: true,
          content: tip,
          distance: 0,
          flip: false,
          interactive: true,
          placement: 'top',
          theme: 'wika light tooltip',
          zIndex: 10000
        })
      })
    },
    closeModal() {
      document.querySelector('.modal.is-active').classList.remove('is-active')

      this.dataSheetUrl = window.createDataSheetUrl || createDataSheetUrl
    },
    closeShopModal() {
      if (window.instance === 'shop') {
        this.dataSheetUrl = window.createDataSheetUrl || createDataSheetUrl
        this.shopPageHeader.style.position = 'sticky'
      }

      this.isModalOpen = false
    },
    openModal() {
      this.shopPageHeader = document.querySelector('.ShopPageHeader__container')
      if (this.shopPageHeader) {
        this.shopPageHeader.style.position = 'static'
      }
    },
    escCloseModal(event) {
      if (event.keyCode === 27) {
        window.instance === 'shop' ? this.closeShopModal() : this.closeModal()
      }
    },
    addToCart() {
      this.addingToCart = true

      setTimeout(() => {
        this.addingToCart = false

        this.closeShopModal()
      }, 1000)
    },
    calcColWidth() {
      const { emTable } = this

      const children = emTable.querySelector('tbody tr:not(.seperator)').children

      const tableWidth = window.instance === 'shop' ? 780 : Math.max(emTable.offsetWidth - 173, 540) // if you change this also take a look in ProductDetailTabs.scss
      const newColWidth = tableWidth / children.length - 21

      Array.from(children).forEach((child) => {
        child.style.minWidth = newColWidth
        child.style.maxWidth = newColWidth
      })
    },
    calcColHeight() {
      const { emTable } = this

      // equalize row cells height

      emTable
        .querySelectorAll('tr:not(.seperator) .fixed-col:not(.rowspan):not(.init)')
        .forEach((col) => {
          const colHeightTh = Math.ceil(Number.parseFloat(getComputedStyle(col).height))
          const colHeightTd = Math.ceil(
            Number.parseFloat(getComputedStyle(col.nextElementSibling).height)
          )

          const { children } = col.parentElement

          Array.from(children).forEach((col) => {
            col.style.height = `${Math.max(colHeightTh, colHeightTd)}px`
          })
        })

      // equalize body cells heights

      const bodyCells = emTable.querySelectorAll(
        'tbody tr:not(.seperator) td:not(.rowspan):not(.init)'
      )

      const heights = Array.from(bodyCells).map((tableCell) =>
        Math.ceil(Number.parseFloat(getComputedStyle(tableCell).height))
      )

      const maxHeight = Math.max(...heights)

      bodyCells.forEach((bodyCell) => (bodyCell.style.height = `${maxHeight}px`))

      // equalize rowspan height

      const rowspan = emTable.querySelector('.fixed-col.rowspan:not(.init)')

      const tbodyHeight = emTable.querySelector('tbody').clientHeight

      const rowPadding =
        rowspan.clientHeight - Number.parseInt(getComputedStyle(rowspan).height, 10)

      const rowspanHeight = Math.max(
        rowspan.querySelector('span').clientHeight,
        tbodyHeight - rowPadding,
        270
      )

      rowspan.style.height = `${rowspanHeight}px`

      // check if rowspan is higher and adjust cells

      if (rowspanHeight > tbodyHeight) {
        const rowsCount = emTable.querySelectorAll('tbody tr:not(.seperator)').length

        bodyCells.forEach(
          (bodyCell) =>
            (bodyCell.style.height = `${(rowspanHeight + rowPadding) / rowsCount - 11}px`)
        )
      }
    },
    setupEvents() {
      this.techInfo.querySelectorAll('.button-navigation').forEach((buttonNavigation) =>
        buttonNavigation.querySelectorAll('.Button__button').forEach((button) => {
          button.addEventListener('click', () => {
            this.emTableSlide(button)

            return false
          })
        })
      )

      if (window.instance === 'shop') {
        this.emTable.querySelectorAll('.after-production').forEach((button) =>
          button.addEventListener('click', (event) => {
            event.preventDefault()
            event.stopPropagation()

            this.displayShopModal(event.currentTarget)
          })
        )

        this.emTable.querySelectorAll('.in-stock').forEach((button) =>
          button.addEventListener('click', (event) => {
            event.preventDefault()
            event.stopPropagation()

            this.displayShopModal(event.currentTarget)
          })
        )
      }
    },
    emTableSlide(button) {
      const buttonNavigation = button.parentElement
      const scrollContainer = this.emTable.parentElement
      const maxScroll =
        this.emTable.querySelector('tbody').offsetWidth - scrollContainer.offsetWidth

      //scroll table
      const scrollWidth = this.emTable.offsetWidth

      this.emTableScrollContainer(scrollWidth * (button.classList.contains('prev') ? -1 : 1))

      //set disabled class for next button
      scrollContainer.scrollLeft < maxScroll
        ? buttonNavigation.querySelector('.next').classList.remove('disabled')
        : buttonNavigation.querySelector('.next').classList.add('disabled')

      //set disabled class for prev button
      scrollContainer.scrollLeft !== 0
        ? buttonNavigation.querySelector('.prev').classList.remove('disabled')
        : buttonNavigation.querySelector('.prev').classList.add('disabled')
    },
    emTableScrollContainer(leftOffset) {
      const scrollContainer = this.emTable.parentElement

      scrollContainer.scrollLeft = scrollContainer.scrollLeft + leftOffset
    },
    priceSum(value) {
      this.quantity = value
    },
    getDeliveryDate() {
      const postData = {
        fromdate: convertISODateTimeToISODate(new Date().toISOString()),
        'ax-system': this.shopConfig.deliveryAxSystem,
        days: this.data.deliveryDays || 4
      }

      getDeliveryDateFromUrl(this.shopConfig.deliveryDateUrl, postData)
        .then((responseData) => {
          const workingDate = convertISODateTimeToLocaleDate(
            JSON.parse(responseData).result[0].workingDate,
            window.currentCountry,
            this.shopConfig.emptyExpDateText
          )

          this.deliveryDate = workingDate
        })
        .catch((error) => console.error(error))
    },
    displayShopModal(modalData) {
      const shopData = modalData.querySelector('.articleinfo')

      this.articleNumber = shopData.id
      this.data.longtext = shopData.value
      this.data.deliveryDays = modalData
        .querySelector(`#DeliveryDays_${shopData.id}`)
        .value.split(';')[0]
      this.data.deliveryTime =
        parseInt(this.data.deliveryDays) + parseInt(this.shopConfig.externalTransport)
      this.data.deliveryCategory = parseInt(
        modalData.querySelector(`#DeliveryCategory_${shopData.id}`).value.split(';')[0]
      )
      this.data.price = modalData.querySelector(`#Price_${shopData.id}`).value.split(';')[0]
      this.price = Number(this.data.price)

      this.data.productType = modalData.querySelector(`#ProductType_${shopData.id}`)?.value
      this.data.btypeList = this.data.productType
        ? [
          {
            btype: this.data.productType,
            minimalPrice: this.price
          }
        ]
        : []

      this.data.deliveryCategory == 1 ? '' : this.getDeliveryDate()
      this.forceRerender()

      this.isModalOpen = true
    },
    getDataSheet() {
      this.isDataSheetLoading = true

      const currentLang = window.currentLanguage.toLowerCase()
      const sessionLoginUrlCompleted = `${window.sessionLoginUrl || sessionLoginUrl
        }&lng=${currentLang}`

      let sessionLogoutUrlCompleted = ''

      const logout = () => {
        axios({
          url: sessionLogoutUrlCompleted,
          method: 'get',
          responseType: 'json',
          withCredentials: false
        })
          .then((logoutResponse) => {
            if (logoutResponse.Status === 'ERROR') {
              console.error(`Session logout failed: ${logoutResponse.ErrorText}`, logoutResponse)
            }
          })
          .catch((error) => console.error('Session logout failed: ', error))
      }

      axios({
        url: sessionLoginUrlCompleted,
        method: 'get',
        responseType: 'json',
        withCredentials: false
      })
        .then((response) => {
          if (response.Status === 'ERROR') {
            console.error(`Session login failed: ${response.ErrorText}`, response)
          } else {
            const dataSheetUrlComplete = `${this.dataSheetUrl}&lng=${currentLang}&session=${response.data.SessionId}&itemid=${this.articleNumber}`

            sessionLogoutUrlCompleted = `${window.sessionLogoutUrl || sessionLogoutUrl}&session=${response.data.SessionId
              }&lng=${currentLang}`

            axios({
              url: dataSheetUrlComplete,
              method: 'get',
              responseType: 'json',
              withCredentials: false
            })
              .then((dataSheetResponse) => {
                if (dataSheetResponse.Status === 'ERROR') {
                  logout()

                  console.error('Load data sheet fialed: ', dataSheetResponse)
                } else {
                  const docFormat = dataSheetResponse.data.ItemBasedDataSheet.DocFormat
                  const item = dataSheetResponse.data.ItemBasedDataSheet.Item
                  const blob = b64toBlob(
                    dataSheetResponse.data.ItemBasedDataSheet.Data,
                    `application/${docFormat}`
                  )
                  const url = window.URL.createObjectURL(blob)

                  let fileName = this.shopConfig.dataSheetText

                  if (fileName === undefined || fileName === 'undefined') {
                    fileName = this.dataSheetText
                  }

                  const a = document.createElement('a')
                  a.href = url
                  a.download = `${fileName}-${item}.${docFormat}`

                  document.body.appendChild(a)

                  a.click()
                  a.remove()

                  this.isDataSheetLoading = false
                  this.$el.querySelectorAll('.ProductDetailTabs__docLink').forEach((link) => {
                    link.innerHTML =
                      '<span><i class="fas fa-file-pdf"></i>' + this.dataSheetText + '</span>'
                  })

                  logout()
                }
              })
              .catch((error) => {
                this.isDataSheetLoading = false
                this.$el.querySelectorAll('.ProductDetailTabs__docLink').forEach((link) => {
                  link.innerHTML =
                    '<span><i class="fas fa-file-pdf"></i>' + this.dataSheetText + '</span>'
                })

                logout()

                console.error('Load data sheet failed: ', error)
              })
          }
        })
        .catch((error) => {
          this.isDataSheetLoading = false
          this.$el.querySelectorAll('.ProductDetailTabs__docLink').forEach((link) => {
            link.innerHTML =
              '<span><i class="fas fa-file-pdf"></i>' + this.dataSheetText + '</span>'
          })

          console.error('Session login failed: ', error)
        })
    }
  }
}
</script>

<style module lang="scss">
@import './ProductDetailTabs.scss';
</style>
