<template>
  <div v-show="!loading" :class="$style.container">
    <a
      id="shopLoginTrigger"
      :class="$style.loginPopper"
      :href="isSSO ? config.ssoUrl : null"
      :role="isSSO ? 'link' : 'button'">
      <i class="fas fa-user" />
      <span v-if="isSSO">
        {{ config.loginText }} / {{ config.registrationText }}
      </span>
      <span v-else>
        {{ loggedIn ? config.loggedInText : config.loginText }}
      </span>
    </a>
    <tippy
      v-if="!isSSO"
      :animate-fill="false"
      :arrow="true"
      :distance="11"
      :duration="[200, 600]"
      :interactive="true"
      :max-width="240"
      :reactive="true"
      :to="'#shopLoginTrigger'"
      :trigger="isMobile ? 'click' : 'mouseenter focus'"
      :z-index="10000"
      animation="fade"
      placement="bottom"
      theme="wika light tooltip"
    >
      <template #content>
        <div id="loginPopperContent" :class="$style.popperContent">
          <ul v-if="loggedIn">
            <li v-for="(link, index) in config.loginMenu" :key="index">
              <a v-if="link.url === 'logout'" @click.prevent="logout" v-html="link.title" />
              <a v-else :href="link.url" v-html="link.title" />
            </li>
          </ul>
          <div v-else class="columns is-mobile is-multiline">
            <form :class="[$style.loginForm, 'column is-full']">
              <div class="columns is-mobile is-multiline">
                <div class="column is-full">
                  <FormInput
                    v-model="email"
                    type="email"
                    :placeholder="config.emailInput"
                    aria-label="enter e-mail"
                    @keyup="enterKeySubmit"
                  />
                  <FormInput
                    v-model="password"
                    type="password"
                    :placeholder="config.passwordInput"
                    aria-label="enter password"
                    @keyup="enterKeySubmit"
                  />
                  <Button colour="orange" :text="config.loginText" :is-form="true" @close="login" />
                </div>
                <div :class="[$style.passReset, 'column is-full']">
                  <a :href="config.passwordUrl">
                    {{ config.passwordText }}
                  </a>
                </div>
              </div>
            </form>
            <div :class="[$style.registration, 'column is-full']">
              <Button
                is-link
                colour="blue"
                :href="config.registrationUrl"
                :text="config.registrationText"
              />
            </div>
          </div>
        </div>
      </template>
    </tippy>
    <Modal :is-modal-open="isModalOpen" @close-modal="closeModal">
      <div :class="$style.modal">
        <h4>{{ config.warningHeadline }}</h4>
        <p>{{ config.warningText }}</p>
      </div>
    </Modal>
  </div>
</template>

<script setup>
import { isMobile as mobileDetected } from 'mobile-device-detect'
import 'url-polyfill'
import { onMounted, onUnmounted, ref, computed } from 'vue'
import ShopPostMessages from '../../globals/constants/shopPostMessages'
import StorageKeys from '../../globals/constants/storageKeys'
import * as Storage from '../../shared/Storage'
import checkLoginWithUrl from '../../xhr/login/checkLoginWithUrl'
import LoginWithUrl from '../../xhr/login/loginWithUrl'
import logoutWithUrl from '../../xhr/login/logoutWithUrl'
import Button from '../Button/Button.vue'
import FormInput from '../FormInput/FormInput.vue'
import Modal from '../Modal/Modal.vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const loading = ref(true)
const loggedIn = ref(false)
const interval = ref(null)
const isMobile = ref(mobileDetected)
const isModalOpen = ref(false)
const email = ref(null)
const password = ref(null)
const isSSO = computed(() => props.config.isSSO === true || props.config.isSSO === 'true')


const openModal = () => {
  isModalOpen.value = true
}

const closeModal = () => {
  isModalOpen.value = false
  window.logInLoading = false
  window.dispatchEvent(new CustomEvent('logInLoading'))
}

const enterKeySubmit = (event) => {
  if (event.keyCode === 13) {
    login()
  }
}

const login = () => {
  window.logInLoading = true
  window.dispatchEvent(new CustomEvent('logInLoading'))

  LoginWithUrl(window.loginUrl, {
    username: email.value,
    password: password.value,
    _t: Date.now()
  })
    .then((responseData) => {
      if (typeof responseData === 'string') {
        setLoggedIn(JSON.parse(responseData))
      } else {
        setLoggedIn(responseData)
      }
    })
    .catch((error) => {
      console.error(error)
      loggedIn.value = false
      openModal()
    })
}

const logout = () => {
  logoutWithUrl(window.logoutUrl)
    .then((responseData) => {
      if (typeof responseData === 'string') {
        setLoggedOut(JSON.parse(responseData))
      } else {
        setLoggedOut(responseData)
      }
    })
    .catch((error) => console.error(error))
}

const checkLogin = (date) => {
  let checkLoginUrl = date ? window.checkLoginUrl + '?_=' + Date.now() : window.checkLoginUrl
  checkLoginWithUrl(checkLoginUrl)
    .then((responseData) => {
      isLoggedIn(responseData)
    })
    .catch((error) => {
      console.error(JSON.parse(JSON.stringify(error)))
    })
    .finally(() => {
      loading.value = false
    })
}

const setLoggedIn = (data) => {
  if (data.status === 1) {
    loggedIn.value = true
    if (props.config.redirectUrl) {
      window.location = props.config.redirectUrl
    }
  } else {
    loggedIn.value = false
    openModal()
  }
}

const setLoggedOut = (data) => {
  if (data.status === 1) {
    if (interval.value) {
      clearInterval(interval.value)
      interval.value = null
    }
    loggedIn.value = false
    Storage.removeSessionStorage(StorageKeys.LOGINDISCOUNT)
    window.location = props.config.homepage || '/'
  } else {
    loggedIn.value = true
  }
}

const isLoggedIn = (data) => {
  const logInDiscountFromStorage = Storage.getSessionStorage(StorageKeys.LOGINDISCOUNT)

  if (data.isLoggedIn === true) {
    loggedIn.value = true
    window.configuratorLoggedIn = true

    if (data.discount && data.typesExcludedDiscount) {
      window.logIn = {
        discount: data.discount,
        typesExcludedDiscount: data.typesExcludedDiscount
      }
    }

    window.logInLoading = false
    window.dispatchEvent(new CustomEvent('logInLoading'))
    window.dispatchEvent(new CustomEvent('logIn'))

    if (
      !logInDiscountFromStorage ||
      logInDiscountFromStorage.discount !== data.discount ||
      logInDiscountFromStorage.typesExcludedDiscount !== data.typesExcludedDiscount
    ) {
      Storage.setSessionStorage(
        StorageKeys.LOGINDISCOUNT,
        {
          discount: data.discount,
          typesExcludedDiscount: data.typesExcludedDiscount
        },
        true
      )
    }

    if (!interval.value) {
      interval.value = setInterval(() => {
        checkLogin()
      }, 30000)
    }
  } else {
    if (loggedIn.value) {
      window.configuratorLoggedIn = false
      logout()
    }
  }
}

const receiveMessage = (event) => {
  const shopBaseUrl = new URL(props.config.shopBaseUrl || location.origin)
  if (event.origin === shopBaseUrl.origin && event.data === ShopPostMessages.CHECK_LOGIN_STATE) {
    const timeoutForCheckLogin = setTimeout(() => {
      checkLogin()
      clearTimeout(timeoutForCheckLogin)
    }, 2000)
  }
}

onMounted(() => {
  window.addEventListener('message', receiveMessage, false)
  checkLogin(true)
})

onUnmounted(() => {
  window.removeEventListener('message', receiveMessage, false)
  if (interval.value) {
    clearInterval(interval.value)
  }
})
</script>

<style module lang="scss">
@import './ShopLogin.scss';
</style>
