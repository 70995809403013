<template>
  <div v-if="results.length !== 0" :class="[$style.container, 'columns is-multiline is-mobile']">
    <div ref="topAnchor" :class="$style.topAnchor" />
    <div
      v-for="(filter, index) in filters"
      :key="index"
      :class="[$style.filter, 'column is-half-mobile is-full-tablet']"
    >
      <a
        :ref="filter.name"
        :class="[$style.content, { ResultFilter__filterActive: currentFilter === filter.name }]"
        @click="startNewSearch(filter.name)"
      >
        <span v-if="filter.label">{{ capitalize(filter.label) }}</span>
        <span>({{ filter.hits }})</span>
      </a>
    </div>
    <a v-if="currentFilter" :class="[$style.reset, 'column is-full']" @click="scrollToAndReset">
      {{ filterResetText }}
      <div ref="bottomAnchor" :class="$style.bottomAnchor" />
    </a>
  </div>
</template>

<script setup>
import smoothscroll from 'smoothscroll-polyfill'
import getSearchResultFromUrl from '../../xhr/search/getSearchResultFromUrl'
import { ref, watch } from 'vue'
import { checkIsMobile } from '../../utils/media'

smoothscroll.polyfill()

const emit = defineEmits(['filtered'])

const props = defineProps({
  filterResetText: {
    type: String,
    default: ''
  },
  results: {
    type: Array,
    default: () => []
  },
  filters: {
    type: Array,
    default: () => []
  },
  terms: {
    type: Array,
    default: () => []
  },
  rangeSize: {
    type: Number
  }
})

const currentFilter = ref(null)
const filteredResults = ref([])
const useCategorySearch = ref(false)
const range = ref({
  from: 0
})
const loading = ref(false)
const totalReached = ref(false)
const isMobile = ref(false)

const searchContainer = window.searchContainer
const disableExternalDocuments = window.disableExternalDocuments
const bottomAnchor = ref(null)
const topAnchor = ref(null)

const capitalize = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

const checkScreenSize = () => (isMobile.value = checkIsMobile())

const initScroll = () => {
  const distanceToStartLoad = 300

  window.onscroll = () => {
    let bottomOfWindow =
      document.documentElement.clientHeight +
        document.documentElement.scrollTop +
        distanceToStartLoad >=
      document.documentElement.scrollHeight

    if (bottomOfWindow && !loading.value && useCategorySearch.value && !totalReached.value) {
      range.value.from = range.value.from + props.rangeSize
      getSearchResults(currentFilter.value, true)
    }
  }
}

const startNewSearch = (category) => {
  if (currentFilter.value === category) {
    return
  }
  range.value.from = 0
  useCategorySearch.value = true
  totalReached.value = false

  checkScreenSize()

  if (isMobile.value) {
    bottomAnchor.value?.scrollIntoView({ behavior: 'smooth' })
  }

  getSearchResults(category)
}

const getSearchResults = (category, onScroll) => {
  loading.value = true
  currentFilter.value = category

  getSearchResultFromUrl(window.categorySearchUrl, {
    word: props.terms,
    from: range.value.from,
    size: props.rangeSize,
    container: searchContainer,
    disableExternalDocuments,
    categoryuid: category
  })
    .then((responseData) => {
      if (onScroll) {
        filterResults(responseData, true)
      } else {
        filterResults(responseData)
      }
    })
    .catch((error) => console.error(error))
}

const filterResults = (data, update) => {
  if (props.filters.length > 1) {
    if (update) {
      filteredResults.value.push(...data.hits.hits)
    } else {
      filteredResults.value = data.hits.hits
      initScroll()
    }

    loading.value = false
    for (const filter of props.filters) {
      if (currentFilter.value === filter.name && filteredResults.value.length === filter.hits) {
        totalReached.value = true
      }
    }
  }
}

const scrollToAndReset = () => {
  checkScreenSize()

  if (isMobile.value) {
    topAnchor.value?.scrollIntoView({ behavior: 'smooth' })
  }

  resetFilters()
}

const resetFilters = () => {
  currentFilter.value = null
  filteredResults.value = props.results
  useCategorySearch.value = false
  emit('filtered', filteredResults.value)
}

watch(
  () => props.terms,
  (newTerms, oldTerms) => {
    if (newTerms !== oldTerms) {
      resetFilters()
    }
  }
)

watch(filteredResults, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue.length) {
    emit('filtered', filteredResults.value)
  }
})

</script>

<style module lang="scss">
@import './ResultFilter.scss';
</style>
