<template>
  <div :class="[$style.contentGrid, 'SubPage__contentGrid']">
    <ElasticSearch :config="config" :model="elasticSearchModel" @submitted="startNewSearch" />
    <p v-if="hint !== hintEnum.EMPTY" :class="$style.info">
      <em class="hlt1">{{ config.textPrefix }}: </em>
      <span v-html="computedHint" />
    </p>
    <p v-if="!!articleNumberSearchResult.articleNumber && !articleNumberSearchResult.erpItem" :class="$style.info">
      <em class="hlt1">{{ config.hintPrefix }}: </em>
      <span>{{
        `"${articleNumberSearchResult.articleNumber}" ${config.hintArticle} "${articleNumberSearchResult.productType}"`
        }}</span>
    </p>
    <p v-if="!!articleNumberSearchResult.productType" :class="$style.info">
      <em class="hlt1">{{ config.hintPrefix }}: </em>
      <span v-html="config.hintArticleInShop" />
    </p>
    <p v-if="totalHits > 0" :class="$style.info" v-html="resultsText" />
    <div class="columns">
      <div class="column is-one-quarter">
        <ResultFilter :range-size="rangeSize" :filterResetText="config.filterResetText" :filters="filters"
          :terms="terms" :results="results" @filtered="displayResults" />
      </div>
      <div class="column is-three-quarters">
        <div class="columns is-multiline">
          <ResultTeaserArticle v-if="showTeaserArticle" :article-number="articleNumberSearchResult.articleNumber"
            :result="teaserArticleResults" :bg-img-url="config.bgImgUrl" class="column is-full" />
          <div v-for="(result, index) in displayedResults" :key="index" class="column is-full">
            <ResultTeaser :key="`resultTeaserKey_${JSON.stringify(result)}`" :result="result"
              :web-base-url="config.webBaseUrl" :shop-base-url="config.shopBaseUrl"
              :textfield-of-activity="config.textfieldOfActivity" :text-location="config.textLocation"
              :text-country="config.textCountry" :text-target-group="config.textTargetGroup"
              :text-employment="config.textEmployment" :search-microsite="config.searchMicrosite" :terms="terms" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios'
import VueSecureHTML from 'vue-html-secure'
import getSearchResultFromUrl from '../../xhr/search/getSearchResultFromUrl'
import ElasticSearch from '../ElasticSearch/ElasticSearch.vue'
import ResultFilter from '../ResultFilter/ResultFilter.vue'
import ResultTeaser from '../ResultTeaser/ResultTeaser.vue'
import ResultTeaserArticle from '../ResultTeaserArticle/ResultTeaserArticle.vue'

import { computed, onMounted, ref, watch } from 'vue'

const PATTERN_ARTICLE_NUMBER = '^[\\d]{6,8}$'
const hintEnum = {
  MIN_LENGTH: 'MIN_LENGTH',
  NO_RESULT: 'NO_RESULT',
  NONE: 'NONE',
  EMPTY: 'EMPTY'
}

const props = defineProps({
  config: {
    type: Object,
    default: () => { }
  },
  rangeSize: {
    type: Number,
    default: 25
  }
})

const terms = ref([])
const displayedResults = ref([])
const teaserArticleResults = ref([])
const totalHits = ref(0)
const results = ref([])
const filters = ref([])
const range = ref({
  from: 0
})
const loading = ref(false)
const useCategorySearch = ref(false)
const articleNumberSearchResult = ref({
  articleNumber: null,
  productType: null
})
const memorizedLabels = ref({}) // in case of scolling result has no labels, so we need this cache
const elasticSearchModel = ref([])
const hint = ref(hintEnum.EMPTY)

const itemInfoUrl = ref(window.getItemInfoUrl)
const showTeaserArticle = ref(false)
const itemInfoHit = ref(null)

const searchContainer = window.searchContainer
const disableExternalDocuments = window.disableExternalDocuments

const resultsText = computed(() => {
  return props.config.resultsText.replace(/{{result}}/g, `<strong>${totalHits.value}</strong>`)
})

const computedHint = computed(() => {
  if (hint.value === hintEnum.MIN_LENGTH) {
    return props.config.textMinLength
  } else {
    return hint.value === hintEnum.NO_RESULT
      ? props.config.textNoResult.replace(
        /{{searchPhrase}}/g,
        `<strong>${VueSecureHTML.escapeHTML(terms.value.join(' '))}</strong>`
      )
      : ''
  }
})

const getPotentialArticleNumber = () => {
  const articleNumberRegEx = new RegExp(PATTERN_ARTICLE_NUMBER, 'gm')
  return terms.value.find((term) => !!articleNumberRegEx.exec(term))
}

const initSearch = () => {
  const queries = window.location.search.substring(1).split('&')
  let queryString = []
  let decodedTerms = []

  if (queries) {
    for (const query of queries) {
      if (query.includes('wika-search')) {
        queryString = query.split('=')[1]
        terms.value = queryString.split('+')
        for (const term of terms.value) {
          decodedTerms.push(decodeURI(term))
        }

        elasticSearchModel.value = decodedTerms

        if (decodedTerms.length === 1 && decodedTerms[0].length < 2) {
          hint.value = hintEnum.MIN_LENGTH
          return
        }

        getSearchResults(elasticSearchModel.value)
      }
    }
  }
}

const initScroll = () => {
  const distanceToStartLoad = 300

  window.onscroll = () => {
    let bottomOfWindow =
      document.documentElement.clientHeight +
      document.documentElement.scrollTop +
      distanceToStartLoad >=
      document.documentElement.scrollHeight

    if (bottomOfWindow && !loading.value && !useCategorySearch.value) {
      range.value.from = range.value.from + props.rangeSize
      getSearchResults(terms.value, true)
    }
  }
}

const processSearch = (data) => {
  totalHits.value = data.totalhits
  if (window.dataLayer) {
    trackSearchResult()
  }

  hint.value = totalHits.value === 0 ? hintEnum.NO_RESULT : hintEnum.EMPTY

  displayedResults.value = []
  setDisplayResults(data.categoryhits)
  setFilters(data.categoryhits)

  results.value = displayedResults.value
  loading.value = false
  initScroll()
}

const trackSearchResult = () => {
  const params = {
    'WT.oss': terms.value.join(' '),
    'WT.oss_r': totalHits.value?.toString()
  }

  window.wtTrackPage(params)
}

const updateSearch = (data) => {
  setDisplayResults(data.categoryhits)
  setFilters(data.categoryhits)

  results.value = displayedResults.value
  loading.value = false
}

const createInfoHit = (terms, catHits, ) => {
  const instanceBasedUrl = `${window.shopStartpageUrl}/?actionType=searchItem`
  const shopUrl = `${window.shopStartpageUrl}/?actionType=searchItem`
  itemInfoHit.value = {
    totalhits: 1,
    _source: {
      name: props.config.itemInfoConfig.name,
      image: props.config.itemInfoConfig.image,
      standardarticle: true,
      erpItem: true,
      description: catHits.Shorttext.replaceAll("\n", " ").replace(/^(.{160}[^\s]*).*/, "$1") + " ...",
      shopUrl: shopUrl || "",
      producttype: catHits.ProductType,
      path: instanceBasedUrl || "",
      categorylabel: props.config.itemInfoConfig.categoryLabel,
      articles: [terms.value[0]],
      categoryuid: props.config.itemInfoConfig.categoryUid || 'products',
    },
    highlight: {
      content: [catHits.Shorttext]
    }
  }
}

const setDisplayResults = (categoryhits) => {
  const isHitsArray = Array.isArray(categoryhits)
  let catHits

  if (isHitsArray) {
    for (const category of categoryhits) {
      if (getPotentialArticleNumber() && category.name === 'products') {
        setArticleNumberSearchResult([...category.result.hits.hits])
      }
      displayedResults.value.push(...category.result.hits.hits)
    }
  } else {
    catHits = categoryhits.categoryhits ? categoryhits.categoryhits : categoryhits

    createInfoHit(terms, catHits)

    if (itemInfoHit.value) {
      getPotentialArticleNumber() &&
        setArticleNumberSearchResult([itemInfoHit.value])
    }
  }

  displayedResults.value.sort((a, b) => b._score - a._score)
}

const setFilters = (categoryhits) => {
  filters.value = []

  if (Array.isArray(categoryhits)) {
    for (const category of categoryhits) {
      if (category.label) {
        memorizedLabels.value[category.name] = category.label
      }

      filters.value.push({
        name: category.name,
        label: memorizedLabels.value[category.name] || category.name,
        hits: category.result.hits.total.value
      })
    }
  } else {
    filters.value.push({
      name: 'produkte',
      label: memorizedLabels.value['produkte'] || 'produkte',
      hits: 1
    })
  }
}

const emptyResultList = () => {
  terms.value = []
  displayedResults.value = []
  totalHits.value = 0
  results.value = []
  filters.value = []
  useCategorySearch.value = false
  range.value.from = 0
}

const startNewSearch = (newTerms) => {
  if (newTerms.length === 1 && newTerms[0].length < 2) {
    emptyResultList()

    hint.value = hintEnum.MIN_LENGTH

    return
  }

  range.value.from = 0
  useCategorySearch.value = false

  articleNumberSearchResult.value = {
    articleNumber: null,
    productType: null
  }

  getSearchResults(newTerms)
}

const getSearchResultFromItemInfo = (newTerm, onScroll) => {
  axios({
    url: window.getItemInfoUrl,
    method: 'POST',
    data: {
      itemid: newTerm,
      country: window.currentCountry,
      language: window.currentLanguage.replace('EN-US', 'EN')
    }
  }).then((responseData) => {
    if (responseData.data.Errorcode == null && responseData.data.Success) {
      if (onScroll) {
        updateSearch({ totalhits: 1, categoryhits: responseData.data })
      } else {
        processSearch({ totalhits: 1, categoryhits: responseData.data })
      }
    }
    else {
      if (onScroll) {
        updateSearch({ totalhits: 0, categoryhits: [] })
      } else {
        processSearch({ totalhits: 0, categoryhits: [] })
      }
    }
  })
    .catch((error) => console.error(error))
}

const getSearchResults = (newTerms, onScroll) => {
  loading.value = true
  terms.value = newTerms

  getSearchResultFromUrl(window.combinedSearchUrl, {
    word: newTerms,
    from: range.value.from,
    size: props.rangeSize,
    container: searchContainer,
    disableExternalDocuments
  })
    .then((responseData) => {
      if (responseData.totalhits === 0 && getPotentialArticleNumber() && itemInfoUrl) {
        try {
          // currently supports a serach of a single article nummer
          getSearchResultFromItemInfo(newTerms[0], onScroll)
        } catch (error) {
          console.error(error)
        }
      } else if (onScroll) {
        updateSearch(responseData)
      } else {
        processSearch(responseData)
      }
    })
    .catch((error) => console.error(error))
}

const displayResults = (newDisplayedResults) => {
  if (results.value.length === newDisplayedResults.length) {
    useCategorySearch.value = false

    initScroll()
  } else {
    useCategorySearch.value = true
  }

  displayedResults.value = newDisplayedResults
}

const setArticleNumberSearchResult = (products) => {
  for (const product of products) {
    if (product._source.standardarticle) {
      articleNumberSearchResult.value = {
        articleNumber: getPotentialArticleNumber(),
        productType: product._source.producttype,
        erpItem: product._source.erpItem
      }
      teaserArticleResults.value = products
    }
  }
}

watch(articleNumberSearchResult, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    showTeaserArticle.value = !!newValue.articleNumber
  }
})

onMounted(initSearch)
</script>

<style module lang="scss">
@import './ResultList.scss';
</style>
