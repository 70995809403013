<template>
  <figure :class="style.container">
    <template v-if="isVideo">
      <a v-if="result._source?.image" :class="style.imageLink" @click="handleTracking($event), (isModalOpen = true)">
        <div :class="style.bgImage" :style="{ backgroundImage: `url(${result._source?.image})` }">
          <img :src="result._source?.image" :alt="result._source?.title" />
          <i v-if="result._source?.archived" :class="['fa fa-archive', style.archived]" />
        </div>
      </a>
      <figcaption>
        <a :class="style.textLink" @click="handleTracking($event), (isModalOpen = true)">
          <div
v-if="result._source?.breadcrumb" :class="style.path"
            v-html="`${result._source?.breadcrumb.replace(/\,/g, ' > ')}`" />
          <div v-else :class="style.path">
            {{ result._source?.categorylabel }}
          </div>
          <h3 v-html="getTitleWithMaxLength(result)" />
          <div v-if="result.highlight && result.highlight.description">
            <span v-html="getDescriptionWithMaxLength(result)" />
          </div>
          <div :class="style.url">{{ resultUrl }}</div>
        </a>
      </figcaption>
      <Modal :is-modal-open="isModalOpen" :is-video="isVideo" @close-modal="isModalOpen = false">
        <iframe
:data-src="resultUrl + '?enablejsapi=1'" frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
      </Modal>
    </template>
    <template v-else>
      <a
        v-if="result._source?.image" :class="style.imageLink" :href="setLinkUrl(resultUrl, terms)"
        :target="getLinkTarget()" @click="handleTracking">
        <div :class="style.bgImage" :style="{ backgroundImage: `url(${result._source?.image})` }">
          <img :src="result._source?.image" :alt="result._source?.title" />
          <i v-if="result._source?.archived" :class="['fa fa-archive', style.archived]" />
        </div>
      </a>
      <figcaption>
        <a
          :class="style.textLink" :href="setLinkUrl(resultUrl, terms)" :target="getLinkTarget()"
          @click="handleDownload($event, resultUrl)">
          <div
            v-if="result._source?.breadcrumb" :class="style.path"
            v-html="`${result._source?.breadcrumb.replace(/\,/g, ' > ')}`" />
          <div v-else :class="style.path">
            {{ result._source?.categorylabel }}
          </div>
          <h3 v-html="getTitleWithMaxLength(result)" />
          <div>
            <span v-html="getDescriptionWithMaxLength(result)" />
          </div>
          <div :class="style.url">{{ resultUrl }}</div>
        </a>
      </figcaption>
    </template>
  </figure>
</template>

<script setup>
import truncate from 'truncate-html'
import Modal from '../Modal/Modal.vue'
import { Category, DocType } from '../../globals/constants/search'
import { endsWith } from '../../utils/helper'
import { useCssModule, ref, onMounted } from 'vue'

const style = useCssModule()

const props = defineProps({
  result: {
    type: Object,
    default: () => { }
  },
  webBaseUrl: {
    type: String,
    default: ''
  },
  shopBaseUrl: {
    type: String,
    default: ''
  },
  textfieldOfActivity: {
    type: String,
    default: ''
  },
  textLocation: {
    type: String,
    default: ''
  },
  textCountry: {
    type: String,
    default: ''
  },
  textTargetGroup: {
    type: String,
    default: ''
  },
  textEmployment: {
    type: String,
    default: ''
  },
  searchMicrosite: {
    type: Boolean,
    default: false
  },
  terms: {
    type: Array,
    default: () => []
  }
})

const resultUrl = ref('')
const isModalOpen = ref(false)
const isVideo = ref(false)
const wtTrack = window.wtTrack

const addDotsToLastElement = (elem) => {
  if (!elem || !elem.lastChild) {
    return
  }

  if (elem.lastChild.nodeType === 3 && elem.lastChild.nodeValue.trim() === '') {
    elem.removeChild(elem.lastChild)
  }

  const { lastChild } = elem

  if (lastChild.nodeType === 1) {
    addDotsToLastElement(lastChild)
  } else if (lastChild) {
    if (!endsWith(lastChild.textContent.trim(), '...')) {
      const span = document.createElement('span')
      span.className = style.dots
      span.innerText = ' ...'
      lastChild.parentNode.appendChild(span)
    }
  }
}

const addTrailingDots = (htmlString) => {
  const parser = new DOMParser()
  const parsedHtml = parser.parseFromString(htmlString, 'text/html')
  addDotsToLastElement(parsedHtml.body)
  return parsedHtml.body.innerHTML
}

const handleTracking = () => {
  const wtParams = props.result._source?.wtParams
  wtParams && wtTrack({ cat: wtParams.doctype }, wtParams)
}

const handleDownload = (event, url) => {
  if (url.endsWith('stp')) {
    event.preventDefault()

    const link = document.createElement('a');
    link.href = url;
    link.download = url.split('/').at(-1)

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }

  handleTracking()
}

const getLinkTarget = () => {
  return props.result._source?.categoryuid === Category.DOWNLOAD ||
    props.result._source?.docType === DocType.BLOG
    ? '_blank'
    : '_self'
}

const getTitleWithMaxLength = (result) => {
  if (result._source?.categoryuid === Category.JOBS) {
    if (result.highlight && result.highlight.position) {
      return result.highlight.position.join(' ')
    } else if (result._source?.position) {
      return result._source?.position
    }
  } else {
    if (result.highlight && result.highlight.title) {
      return result.highlight.title.join(' ')
    } else if (result._source?.title) {
      return result._source?.title
    }
  }
}

const getDescriptionWithMaxLength = (result) => {
  if (result._source?.categoryuid === Category.JOBS) {
    var returnString = ''
    if (result.highlight && result.highlight.fieldOfActivity) {
      returnString +=
        props.textfieldOfActivity + ': ' + result.highlight.fieldOfActivity.join(' ') + ' - '
    } else if (result._source?.fieldOfActivity) {
      returnString += props.textfieldOfActivity + ': ' + result._source?.fieldOfActivity + ' - '
    }
    if (result.highlight && result.highlight.location) {
      returnString += props.textLocation + ': ' + result.highlight.location.join(' ') + ' - '
    } else if (result._source?.location) {
      returnString += props.textLocation + ': ' + result._source?.location + ' - '
    }
    if (result.highlight && result.highlight.country) {
      returnString += props.textCountry + ': ' + result.highlight.country.join(' ') + ' - '
    } else if (result._source?.country) {
      returnString += props.textCountry + ': ' + result._source?.country + ' - '
    }
    if (result.highlight && result.highlight.targetGroup) {
      returnString += props.textTargetGroup + ': ' + result.highlight.targetGroup.join(' ') + ' - '
    } else if (result._source?.targetGroup) {
      returnString += props.textTargetGroup + ': ' + result._source?.targetGroup + ' - '
    }
    if (result.highlight && result.highlight.employment) {
      returnString += props.textEmployment + ': ' + result.highlight.employment.join(' ')
    } else if (result._source?.employment) {
      returnString += props.textEmployment + ': ' + result._source?.employment
    }
    return returnString
  } else {
    if (props.searchMicrosite) {
      if (result.highlight && result.highlight.contentUnstemmed) {
        return cropTextIfTooLong(result.highlight.contentUnstemmed.join(' '))
      } else if (result._source?.description) {
        return cropTextIfTooLong(result._source?.description)
      } else {
        return ''
      }
    } else {
      if (result.highlight && result.highlight.description) {
        return cropTextIfTooLong(result.highlight.description.join(' '))
      } else if (result._source?.description) {
        return cropTextIfTooLong(result._source?.description)
      } else {
        return ''
      }
    }
  }
}

const cropTextIfTooLong = (text) => {
  const textWithoutParagraph = text.replace(/<p>/g, '').replace(/<\/p>/g, '')

  if (props.result._source?.categoryuid === 'contacts') {
    return textWithoutParagraph.trim()
  }

  const description = truncate(textWithoutParagraph, 160, {
    ellipsis: '',
    reserveLastWord: true
  })
    .trim()
    .replace(/[\\.]{2,3}$/gm, '')

  return addTrailingDots(description)
}

const setResultUrlWithOptionalPrefix = (path, baseUrl) => {
  if (path.indexOf('http') === 0) {
    resultUrl.value = props.result._source?.path
  } else {
    resultUrl.value = baseUrl + props.result._source?.path
  }
}

const setLinkUrl = (url, term) => {
  try {
    const modifiedUrl = new URL(url)

    // exclude extension to prevent wrong download format
    const excludedFileExtensions = ['stp', 'pdf']

    for (const excludedFileExtension of excludedFileExtensions) {
      if (modifiedUrl.pathname.endsWith(`.${excludedFileExtension}`)) {
        return url
      }
    }

    // add highlight
    modifiedUrl.searchParams.append('highlightedText', term.slice(0, 1))

    return modifiedUrl.toString()
  } catch {
    return url
  }
}

const preprocessResultCategory = () => {
  switch (props.result._source?.categoryuid) {
    case Category.SHOP:
      setResultUrlWithOptionalPrefix(props.result._source?.path, props.shopBaseUrl)
      break
    case Category.VIDEOS:
      resultUrl.value = props.result._source?.path
      isVideo.value = true
      break
    case Category.BLOG:
      resultUrl.value = props.result._source?.path
      break
    default:
      setResultUrlWithOptionalPrefix(props.result._source?.path, props.webBaseUrl)
      break
  }
}

onMounted(() => {
  if (props.result._source) {
    preprocessResultCategory()
  }
})
</script>

<style module lang="scss">
@import './ResultTeaser.scss';
</style>
