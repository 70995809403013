<template>
  <div :class="$style.container">
    <Search
      v-if="initialized"
      :search-placeholder="config?.placeholder"
      :selected-suggestion="selectedSuggestion"
      :model="model"
      :show-did-you-mean="didYouMeanVisible"
      :search-synonym-suggest="searchSynonymSuggest"
      :search-synonym="searchSynonym"
      @submitted="submitSearch"
      @active="getSearchResults"
      @inactive="handleInactive"
      @key-up-up="handleKeyUp"
      @key-up-down="handleKeyDown"
      @key-up-enter="submitSearch"
      @change="handleSearchInputChange"
      @initialize="handleSearchInputInitialize"
    />
    <ul v-if="suggestions.length !== 0" v-show="active" :class="$style.suggestions">
      <li
        v-for="(suggestion, index) in suggestions"
        :key="index"
        :class="[$style.suggestion, { [$style.hover]: index === selectedSuggestionIndex }]"
        @click="() => handleSuggestionClick(suggestion.text)"
      >
        <span v-html="suggestion.text" />
      </li>
    </ul>
  </div>
</template>

<script setup>
import axios from 'axios'
import Search from '../Search/Search.vue'
import getSearchSynonyms from '../../xhr/search/getSearchSynonyms'

import { computed, onMounted, ref } from 'vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  },
  model: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(['submitted'])

const searchSynonymSuggest = ref([])
const suggestions = ref([])
const selectedSuggestion = ref('')
const active = ref(false)
const selectedSuggestionIndex = ref(-1)
const searchSynonym = ref(null)
const searchSynonyms = ref(null)
const initialized = ref(false)
const terms = ref([])

const searchContainer = window.searchContainer
const disableExternalDocuments = window.disableExternalDocuments

const didYouMeanVisible = computed(() => {
  return !active.value || suggestions.value.length === 0
})

const handleSearchInputInitialize = (term, preventSuggestions) => {
  handleSearchInputChange(term)
  if (!searchSynonym.value) {
    getSearchResults(term, preventSuggestions)
  }
}

const handleSearchInputChange = (term) => {
  searchSynonym.value = null

  if (term.length > 0) {
    checkSearchSynonym(term)
  }
}

const findSearchSynonym = (synonyms, term) => {
  const termLowerCase = term.toLowerCase()

  for (const key in synonyms) {
    for (const workKey in synonyms[key]) {
      const word = synonyms[key][workKey]
      if (word.toLowerCase() === termLowerCase) {
        return key
      }
    }
  }
}

const checkSearchSynonym = (term) => {
  const joinedTerms = term || terms.value.join(' ')
  searchSynonym.value = findSearchSynonym(searchSynonyms.value, joinedTerms)
}

const resetSearchSynonymSuggest = () => {
  searchSynonymSuggest.value = []
}

const handleInactive = () => {
  emptySuggestions()
}

const submitSearch = (newTerms) => {
  resetSearchSynonymSuggest()

  if (props.config?.headerSearch) {
    const queryTerms = newTerms.toString().replace(/,/g, '+')
    const submitUrl = props.config.submitUrl + (queryTerms ? `?wika-search=${queryTerms}` : '')
    window.location = submitUrl
  } else if (newTerms[0] !== '') {
    active.value = false
    emit('submitted', newTerms)
  }
}

const getSearchResults = (newTerms, preventSuggestions) => {
  if (!newTerms.length || (newTerms.length === 1 && newTerms[0].length < 2)) {
    emptySuggestions()
  } else {
    const country =
      window.searchCountry !== undefined ? window.searchCountry : window.currentCountry
    axios({
      url: window.suggestSearchUrl,
      method: 'post',
      data: {
        word: newTerms,
        language: window.currentLanguage.replace('EN-US', 'EN'),
        country: country,
        container: searchContainer,
        disableExternalDocuments
      }
    })
      .then((response) => processSearch(response.data, preventSuggestions, newTerms))
      .catch((error) => console.error(error))
  }
}

const emptySuggestions = () => {
  active.value = false
  suggestions.value = []
  resetSearchSynonymSuggest()
}

const processSynonyms = (data) => {
  if (suggestions.value.length === 0 && data.suggest && data.suggest['content-term-suggestion']) {
    const searchSynonymSuggest = data.suggest['content-term-suggestion']
      .map((word) => {
        if (word.options.length > 0) {
          return word.options.sort((a, b) => b.score - a.score)[0].text
        }
      })
      .filter((word) => word)

    searchSynonymSuggest.value = searchSynonymSuggest
  } else {
    resetSearchSynonymSuggest()
  }
}

const processSearch = (data, preventSuggestions) => {
  if (!preventSuggestions) {
    if (data.suggest && data.suggest['prefix-suggest'] && data.suggest['prefix-suggest'][0]) {
      active.value = true;
      suggestions.value = data.suggest['prefix-suggest'][0].options;
      selectedSuggestionIndex.value = -1;
    } else {
      console.error("Suggest data is not available");
    }
  }
  processSynonyms(data);
}

const handleSuggestionClick = (suggestion) => {
  selectedSuggestion.value = suggestion
  active.value = false
  submitSearch([suggestion])
}

const handleKeyUp = () => {
  if (selectedSuggestionIndex.value > 0) {
    selectedSuggestionIndex.value--
    selectedSuggestion.value = suggestions.value[selectedSuggestionIndex.value].text
  }
}

const handleKeyDown = () => {
  if (selectedSuggestionIndex.value < suggestions.value.length - 1) {
    selectedSuggestionIndex.value++
    selectedSuggestion.value = suggestions.value[selectedSuggestionIndex.value].text
  }
}

onMounted(() => {
  getSearchSynonyms(window.searchSynonymUrl).then((synonyms) => {
    searchSynonyms.value = synonyms
    initialized.value = true
  })
})
</script>

<style module lang="scss">
@import './ElasticSearch.scss';
</style>
