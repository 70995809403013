<template>
  <iframe
    :id="config.id"
    :class="$style.iframe"
    :src="config.src"
    :style="{ height: config.height, 'min-width': config.width }"
    @load="iframeLoaded"
  />
</template>

<script setup>
import iFrameResize from '@iframe-resizer/parent'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const emit = defineEmits(['loaded'])

const iframeLoaded = () => {
  emit('loaded')

  iFrameResize(
    {
      scrolling: 'omit',
      onInit: (iframe) => {
        iframe.scrolling = 'no'
        iframe.style.minWidth = '100%'
        iframe.iFrameResizer.resize()
      },
      license: '1jy4dww5qzv-s54r73oxcn-v59f4kfgfz'
    },
    '#' + props.config.id
  )
}
</script>

<style module lang="scss">
@import './IFrameResizer.scss';
</style>
