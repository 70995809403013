const MwfFormNamesAndIds = {
  FORM_ELEMENT_SEQUENCE: 'mwf-formElement-sequence',
  COUNTRY: 'country',
  DOMAIN: 'domain',
  LANGUAGE: 'language',
  PAGE_URL: 'pageUrl',
  FORM_ID: 'formId',
  TOKEN: 'token',
  MWF_TOKEN: 'mwf-token'
}

export { MwfFormNamesAndIds }
