<template>
  <div v-if="loading" class="preloadFilter">
    <Loading />
  </div>
  <div v-else-if="isTeaserVisible">
    <figure
      :class="[$style.container, { ResultTeaserArticle__shopContainer: isShop }]"
      :style="[isShop ? { backgroundImage: `url(${bgImgUrl})` } : { backgroundImage: 'none' }]"
    >
      <!-- IMAGE -->
      <div :class="$style.imageLink">
        <div :class="$style.bgImage" :style="{ backgroundImage: `url(${article.image})` }">
          <img :src="article.image" :alt="article.title" />
          <i v-if="article.archived" :class="['fa fa-archive', $style.archived]" />
        </div>
      </div>

      <!-- DETAILS -->
      <figcaption>
        <div :class="$style.supline">
          {{ dictionary.articleNumber }} {{ shopArticle.ItemId }} -
          {{ renderTypesLabel() }}
          {{ renderTypes() }}
        </div>
        <h3 v-html="article.title" />
        <div v-html="article.description" />
        <div :class="$style.linkDetail" @click="openModal">
          {{ dictionary.details }}
        </div>

        <!-- SHOP -->
        <div :class="$style.shop">
          <!-- PRICE -->
          <div :class="$style.shopPrice">
            <strong>
              <IndividualPrice
                :value="Number(shopArticle.UnitPrice)"
                :quantity="Number(shopArticle.Quantity)"
                :product-type="shopArticle.MainType"
                :btype-list="shopArticle.btypeList"
              />
            </strong>
            <span>
              {{ dictionary.plusVat }} &amp;
              <a :href="shippingURL">{{ dictionary.shipping }}</a>
            </span>
          </div>

          <!-- SHOPCARTLINK -->
          <ShopCartLink
            v-if="isShop"
            :key="`cartLink-${shopArticle.ItemId}`"
            :item="{
              text: dictionary.addToCart,
              quantity: '1',
              id: shopArticle.ItemId,
              headline: article.title,
              subheadline: article.subHeadline,
              pictureurl: article.image,
              thumbnailurl: article.image,
              producturl: article.shopUrl
            }"
            :add-item-failed-msg="dictionary.addItemFailed"
            :add-button-loading-text="dictionary.addToCart"
          />
          <Button
            v-else
            colour="orange"
            :href="`${article.shopUrl}&itemId=${articleNumber}`"
            :text="dictionary.goToOnlineShop"
            is-link
          />
        </div>
      </figcaption>
    </figure>

    <!-- MODAL -->
    <Modal :is-modal-open="isModalOpen" @close-modal="closeModal">
      <div :class="$style.modal">
        <p :class="$style.heading">{{ dictionary.articleNumber }} {{ articleNumber }}</p>
        <div class="columns is-gapless">
          <div class="column is-two-thirds-tablet">
            <p :class="$style.subHeading">
              {{ dictionary.details }}
            </p>
            <p v-html="replaceNewLine(shopArticle.Longtext || shopArticle.Shorttext)" />
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script setup>
import axios from 'axios'
import Button from '../Button/Button.vue'
import Loading from '../Loading/Loading.vue'
import ShopCartLink from '../ShopCartLink/ShopCartLink.vue'
import Modal from '../Modal/Modal.vue'
import { Category } from '../../globals/constants/search'
import { replaceNewLine } from '../../utils/helper'
import { computed, onMounted, ref, watch } from 'vue'
import IndividualPrice from '../IndividualPrice/IndividualPrice.vue'

const props = defineProps({
  articleNumber: {
    type: String
  },
  bgImgUrl: {
    type: String
  },
  result: {
    type: Object,
    default: () => {}
  }
})

const dictionary = window.dictionary
const shippingURL = window.shippingURL
const isShop = window.instance === 'shop'

const isModalOpen = ref(false)
const lastShopArticle = ref(undefined)
const shopArticle = ref(undefined)
const loading = ref(false)

const isTeaserVisible = computed(() => {
  return article.value && shopArticle.value && props.articleNumber === shopArticle.value.ItemId
})

const article = computed(() => {
  const articles = props.result.filter((item) => {
    return (
      item._source?.categoryuid === Category.PRODUCTS &&
      item._source?.articles &&
      [...item._source.articles].indexOf(props.articleNumber) !== -1
    )
  })

  if (articles.length > 0) {
    const { _id: id, _source } = articles[0]

    return {
      id,
      ..._source
    }
  }

  return undefined
})

const openModal = () => {
  isModalOpen.value = true
}

const closeModal = () => {
  isModalOpen.value = false
}

const renderTypesLabel = () => {
  const { MainType: type } = shopArticle.value
  const { productType, productTypes } = dictionary

  return type.trim().split(' ').length > 1 ? productTypes : productType
}

const renderTypes = () => {
  const { MainType: type } = shopArticle.value

  return type
    .split(' ')
    .filter((value, index, self) => self.indexOf(value) === index)
    .join(', ')
}

const getShopArticle = () => {
  loading.value = true

  if (!article.value || !props.articleNumber || props.articleNumber === lastShopArticle.value) {
    loading.value = false

    return
  }

  lastShopArticle.value = props.articleNumber

  axios
    .post(window.getItemInfoUrl, {
      // ? https://shop.wika.com/rest-services/shop/getiteminfos
      country: window.currentCountry,
      itemid: props.articleNumber,
      language: window.currentLanguage
    })
    .then((response) => {
      if (response.data.Success) {
        shopArticle.value = response.data
      }

      loading.value = false
    })
    .catch(() => {
      shopArticle.value = undefined
      loading.value = false
    })
}

watch(article, (newArticle, oldArticle) => {
  if (!newArticle) {
    return
  }
  if (!oldArticle || newArticle.id !== oldArticle.id) {
    getShopArticle()
  }
})

onMounted(() => {
  getShopArticle()
})
</script>

<style module lang="scss">
@import './ResultTeaserArticle.scss';
</style>
