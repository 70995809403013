<template>
  <div>
    <div v-if="!consentGiven" :class="$style.consent">
      <h2>{{ dictionary.consentHeading }}</h2>
      <div v-html="vendorConsentText" />
      <Button is-close :class="$style.consentButton" :text="consentButtonLabel" @close="handleContentButton" />
    </div>
    <template v-if="consentGiven">
      <div :id="containerId" />
      <ReCaptcha v-if="config.useRecaptcha" :execute-switch="executeSwitch" :submit="getToken" />
    </template>
  </div>
</template>

<script setup>
import { onMounted, onUpdated, ref, watch } from 'vue'
import { getVendorConsent, registerConsentListeners } from '../../globals/consentmanager'
import cmpVendorIDs from '../../globals/constants/cmpVendorIDs'
import { MwfFormNamesAndIds } from '../../globals/constants/mwf'
import { getMarketingConsent } from '../../globals/cookiebot'
import Button from '../Button/Button.vue'
import ReCaptcha from '../ReCaptcha/ReCaptcha.vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => { }
  }
})

const vendor = cmpVendorIDs.GRECAPTCHA
const containerId = ref(null)
const executeSwitch = ref(null)
const consentGiven = ref(window.Cookiebot ? getMarketingConsent() : getVendorConsent(vendor.id))

const dictionary = window.dictionary
const vendorConsentText = dictionary.consentText.replaceAll("###VENDOR_NAME###", vendor.name).replaceAll("#<VENDOR_NAME>#", vendor.name)
const consentButtonLabel = dictionary.consentButtonLabel.replaceAll(/&apos;/g, "'")

const formcentricScript = ref(null)
const isFCInitialised = ref(false)

const checkVendorConsent = () => {
  consentGiven.value = getVendorConsent(vendor.id)
  checkConsent()
}

const handleContentButton = () => {
  if (window.cmpConsentType === 'consentmanager') {
    window.__cmp('setVendorConsent', [vendor.id, 1], () => {
      consentGiven.value = getVendorConsent(vendor.id)
    })
  } else {
    if (window.Cookiebot) {
      window.Cookiebot.onaccept = () => {
        consentGiven.value = getMarketingConsent()
      }
      window.Cookiebot.show()
    }
    checkConsent()
  }
}

const checkConsent = () => {
  if (consentGiven.value && props.config.fcScript) {
    initFormcentric()
  }
}

const getToken = (token) => {
  const tokenField = document.getElementById(MwfFormNamesAndIds.TOKEN)

  if (tokenField) {
    tokenField.value = token
  }
}

const initFormcentric = () => {
  containerId.value = props.config.selector.replace('#', '')
  formcentricScript.value = props.config.fcScript
  const allScripts = document.getElementsByTagName('script')

  for (const script of allScripts) {
    isFCInitialised.value = script.src === formcentricScript.value
  }

  if (formcentricScript.value && !isFCInitialised.value) {
    const embedFormcentricScript = document.createElement('script')
    embedFormcentricScript.setAttribute('src', formcentricScript.value)
    document.body.appendChild(embedFormcentricScript)
    isFCInitialised.value = true
  }
}

const registerConsentListenersWithDelay = (checkVendorConsent) => {
  const intervalId = setInterval(() => {
    if (typeof window.__cmp === 'function') {
      registerConsentListeners(checkVendorConsent);
      clearInterval(intervalId);
    }
  }, 100);
  setTimeout(() => clearInterval(intervalId), 5000);
};

watch(isFCInitialised, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue) {
    if (window.formcentric) {
      window.formcentric.initFormcentric()
    }
  }
})

onMounted(() => {
  consentGiven.value = window.Cookiebot ? getMarketingConsent() : getVendorConsent(vendor.id)

  if (window.cmpConsentType === 'consentmanager') {
    registerConsentListenersWithDelay(checkVendorConsent);
    setTimeout(() => {
      checkVendorConsent();
    }, 1000);
  } else {
    checkConsent()
  }
})

onUpdated(() => {
  if (window.cmpConsentType === 'consentmanager') {
    registerConsentListeners(checkVendorConsent)
    checkVendorConsent()
  } else {
    checkConsent()
  }

  consentGiven.value = window.Cookiebot ? getMarketingConsent() : getVendorConsent(vendor.id)
})

</script>

<style module lang="scss">
@import './MondayWebforms.scss';
</style>
